import ImagesLoader from "./image-loader";

export default (parameters = {}) => {
    const configuration = Object.assign({
        /*TODO: additional variables*/
        colors: [],
    }, JSON.parse(parameters));
    return {
        ...configuration,
        selectedColor: null,
        selectedImageIndex: 0,
        isLoaded: false,
        start() {
            let images = [];
            this.selectedColor = this.colors[0];
            // TODO: Load images
            setTimeout(()=> {

            for (const color of this.colors) {
                for (const image of color.images) {
                    images.push(image);
                }
            }
            },1000)

            this.handleLoading(images).then(() => {

            });
        },
        handleLoading(images) {
            return ImagesLoader(images);
        },
        selectColor(color) {
            this.selectedColor = color;
        },
        nextImage() {
            if (this.selectedImageIndex < this.selectedColor.images.length - 1) {
                this.selectedImageIndex++;
            } else {
                this.selectedImageIndex = 0;
            }
        },
        previousImage() {
            if (this.selectedImageIndex > 0) {
                this.selectedImageIndex--;
            } else {
                this.selectedImageIndex = this.selectedColor.images.length - 1;
            }
        }
    }
};
