export default (parameters = {}) => {
    const configuration = Object.assign({
        colors: [],
    }, JSON.parse(parameters));
    return {
        ...configuration,
        selectedColor: null,
        selectedSubColorIndex: 0,
        isLoaded: false,
        start() {
            this.selectedColor = this.colors[0];
        },
        selectColor(color, index) {
            this.selectedColor = color;
            this.selectedSubColorIndex = index;
        }
    }
}
