export default () => ({
    tabSelector: 'tab-selector-wrapper',
    scrollTopOffset: 10,
    mobileMenuVisible: false,
    subNavigation: null,
    initialMenuPosition: null,
    headerTranslation: null,
    isSecondarySticky: false,
    hasBackground: false,
    init() {
        this.resetSizes();
    },
    toggleMenu() {
        if (this.subNavigation) {
            this.subNavigation = null;
        } else {
            this.mobileMenuVisible = !this.mobileMenuVisible;
        }
    },
    resetSizes() {
        let tabWrapper = document.getElementsByClassName(this.tabSelector)[0];
        if (tabWrapper) {
            this.initialMenuPosition = tabWrapper.offsetTop !== 0 && this.initialMenuPosition !== 0 ? tabWrapper.offsetTop : this.initialMenuPosition ;
            tabWrapper.classList.remove('is-sticky')
        }
        //
        // if (this.initialMenuPosition === 0) {
        //     window.scrollTo(0,0);
        //     this.resetSizes();
        // }
    },
    showSubNavigation(item) {
        // TODO: Use actual menu objects
        this.subNavigation = item;
    },
    hideOverlay() {
        this.subNavigation = null
    },
    calculateStickyElements() {
        const scrollTop = window.scrollY;
        // Change background color of header
        this.hasBackground = scrollTop > this.scrollTopOffset;

        if (this.initialMenuPosition === null) {
            return;
        }
        const headers = document.getElementsByClassName('header')
        if (headers.length === 0) {
            return;
        }

        const headerHeight = headers[0].offsetHeight;
        const translateBy = this.initialMenuPosition - (scrollTop + headerHeight);

        if (scrollTop + headerHeight >= this.initialMenuPosition) {
            this.translateHeaderBy(translateBy);
            this.isSecondarySticky = scrollTop >= this.initialMenuPosition;
        } else {
            if (translateBy <= 0) {
                this.translateHeaderBy(Math.max(0, translateBy));
            } else {
                this.translateHeaderBy(0);
                this.isSecondarySticky = false;
            }
        }

        let tabWrapper = document.getElementsByClassName(this.tabSelector)[0];
        if (tabWrapper) {
            if (this.isSecondarySticky) {
                tabWrapper.classList.add('is-sticky')
            } else {
                tabWrapper.classList.remove('is-sticky')
            }

        }

    },
    translateHeaderBy(offset) {
        this.headerTranslation = {
            'transform': `translateY(${offset}px)`
        }
    }
})
