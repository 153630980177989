document.addEventListener("DOMContentLoaded", (event) => {

    // action elements

    const actionBarElements = document.querySelectorAll('.action-bar-item');

    if (actionBarElements) {
        for (let element of actionBarElements) {
            element.addEventListener('click', e => {
                window.dataLayer.push({
                    'event': 'action-bar-click'
                })
            })
        }
    }

});
