export default (sliderId, id, params) => ({
    sliderId,
    id,
    active: 0,
    glideInstance: undefined,
    init() {

        const sw = new Glide('.eclick-changing-' + sliderId, params);

        sw.on('move.after', (e) => {
            this.active = sw.index;
        })


        // this.glideInstance = sw;
        sw.mount();


        const slides = document.querySelectorAll(`.eclick-changing-${sliderId} .glide__slide`);

        for (let slide of slides) {
            slide.addEventListener('click', function (e) {
                console.log('asd')
                var ind = parseInt(slide.getAttribute('data-index'))
                if (ind === 0 || ind > 0) {
                    console.log(slide.getAttribute('data-index'))
                    console.log('go')
                    sw.go('=' + ind)
                }
            })
        }

        // this.glideInstance = new Glide('.glide-slider', params);
        // this.glideInstance.mount();

    },
    // slideToIndex(index) {
    //     console.log(this.glideInstance)
    //     this.glideInstance.go('=' + index)
    // }

})
