import swiperStyle from '../sass/common/swiper.scss?inline'

let activeIndex;
export default (id, params) => ({
    id,
    active: 0,
    swiperElement: '',
    init() {
        const swiperEl = document.querySelectorAll(`[data-swiper=${id}]`);
        let activeSlide = 1;
        for (let swiper of swiperEl) {
            const swiperParams = {
                injectStyles: [swiperStyle],
                centeredSlides: false,
                navigation: true,
                pagination: {
                    clickable: true
                },
                ...params,
            }

            Object.assign(swiper, swiperParams);
            activeSlide = 1;

            swiper.addEventListener('slidechange', (event) => {
                this.active = event.detail[0].activeIndex
                console.log(event.detail)
            });

            try {
                swiper.initialize()
                this.swiperElement = swiper
                this.swiperElement.swiper.snapGrid[ this.swiperElement.swiper.snapGrid.length - 1] =  this.swiperElement.swiper.slidesGrid[ this.swiperElement.swiper.slidesGrid.length - 1];
            } catch (e) {
                console.log(e)
            }
        }

    },

    slideNext(index) {
        // this.swiperElement.swiper.slideNext();
        console.log('slidenext')
        console.log(this.swiperElement.swiper.activeIndex)

        this.swiperElement.swiper.activeIndex = index;
        console.log(this.swiperElement.swiper.activeIndex)
    },

    slidePrev() {
        this.swiperElement.swiper.slidePrev();
    }
})
